import { Employer } from "@/dto/employer/employer";
import { Company } from "@/dto/company/company";
import { useFetchStateStore } from "@/store/fetch-state";

export const useEmployerStore = defineStore("employer", () => {
  const supabase = useSupabaseClient();
  const employer = ref<Employer>();
  const fetchStateStore = useFetchStateStore();

  const getEmployer = async (employerId: string) => {
    await fetchStateStore.fetchOnce("employer", async () => {
      employer.value = await $api<Employer>(`${API_ROUTES.employer}/${employerId}`);
    });
  };

  const createEmployer = async (data: any) => {
    const emp = await $api<Employer>(API_ROUTES.employer, {
      method: "post",
      body: { ...employer.value, ...data },
    });
    if (emp) employer.value = emp;
  };

  const updateEmployer = async ({ employerData, profileImg, initial }: { employerData: any; profileImg?: File; initial?: boolean }) => {
    if (!employer.value) throw createError({ statusMessage: ERROR_MESSAGES.employer_not_found });

    const empId = employer.value.id;

    if (profileImg) {
      const { error } = await supabase.storage.from(SUPABASE_BUCKETS.profileImages).upload(empId, profileImg, {
        upsert: true,
      });
      if (error) return { error };

      const { data } = supabase.storage.from(SUPABASE_BUCKETS.profileImages).getPublicUrl(empId);
      employerData.profile_image = data.publicUrl;
    }

    const emp = await $api<Employer>(`${API_ROUTES.employer}/${empId}`, {
      method: "patch",
      body: { data: { ...employer.value, ...employerData }, initial },
    });

    if (emp) employer.value = emp;
    return { error: null };
  };

  const deleteEmployer = async () => {
    await $api<Employer>(`${API_ROUTES.employer}/${employer.value!.id}`, {
      method: "delete",
    });
  };

  const updateRole = (updatedMember: Employer) => {
    if (employer.value!.id === updatedMember.id) {
      employer.value!.role = updatedMember.role;
      setPermissions(employer.value!);
    }
  };

  const sendContactSupportEmail = async ({ message, company }: { message: string; company: Company }) => {
    await $api<{ success: boolean }>(`${API_ROUTES.contact}/support`, {
      method: "post",
      body: { message, employer: { ...employer.value, company } },
    });
  };

  return { employer, getEmployer, createEmployer, updateEmployer, deleteEmployer, updateRole, sendContactSupportEmail };
});
